<template>
  <div class="lean-improvement-changeover-wrapper pt-2 pb-3" v-if="game">
    <b-field position="is-centered" grouped class="mb-2">
      <b-select placeholder="No Kanban/Stock limit" v-model="allFaciKanban">
        <option
          v-for="kab in [null, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          :value="kab"
          :key="kab"
        >
          {{ kab ? `${kab} units` : "No limit" }}
        </option>
      </b-select>
      <p class="control">
        <b-button @click="applyKanbanToAll()" type="is-info" rounded
          >Apply to all stations</b-button
        >
      </p>
    </b-field>
    <div
      class="is-divider divider-text-darker"
      data-content="Or customize the limit for each station:"
    ></div>

    <div class="columns is-centered is-multiline">
      <b-field
        class="column is-3 mb-0"
        v-for="faci in facis"
        :key="faci.id"
        position="is-centered"
        :label="faci.name"
      >
        <b-select placeholder="No limit" v-model="faci.params.kanbanCapa">
          <option
            v-for="kab in [null, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            :value="kab"
            :key="kab"
          >
            {{ kab ? `${kab} units` : "No limit" }}
          </option>
        </b-select>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeanImprovementKanban",
  props: {
    game: Object
  },
  computed: {
    facis() {
      return Object.values(this.newFacilities).filter(fac => {
        return fac.type !== "inventoryShipping";
      });
    }
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      this.readyWatch = false;
      this.newFacilities = JSON.parse(JSON.stringify(this.game.facilities));
      setTimeout(() => {
        this.readyWatch = true;
      }, 5);
    },
    applyKanbanToAll() {
      this.facis.forEach(fac => {
        if (fac.params) {
          fac.params.kanbanCapa = this.allFaciKanban;
        }
      });
    }
  },
  watch: {
    newFacilities: {
      handler: function() {
        if (this.readyWatch) {
          this.$emit("update", { type: "kanban", obj: this.newFacilities });
        }
      },
      deep: true
    }
  },
  data() {
    return {
      allFaciKanban: null,
      readyWatch: false,
      newFacilities: {}
    };
  }
};
</script>

<style lang="scss">
.lean-improvement-kanban-wrapper {
}
</style>
